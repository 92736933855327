<template>
  <div class="iframe-container">
    <iframe
        src="https://fastgpt.cmclouds.com/chat/share?shareId=415a8vh5oq8nycqz4jjoybbj"
        style="width: 100%; height: 100%;"
        border="0"
        allow="*"
    ></iframe>
  </div>
</template>

<script>

  import { mapGetters, mapMutations } from 'vuex'
  import store from "../../../store";
  import router from "../../../router";
  export default {
    name: "IframePage",

    computed: {
      ...mapGetters([
        'token',
        'myInfo',
      ])
    },

    mounted() {
      let that=this;
      if (!that.token || that.token == '')
        setTimeout(() => {
          store.dispatch('Logout').then(() => {
            router.replace({name: 'StudentLogin'})
          })
        }, 2000)
    },
  };

</script>

<style scoped>
.iframe-container {
  width: 100%;
  height: 90vh; /* 设置 iframe 的高度占满视窗。注意减去导航栏高度！ */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
